import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import bannerImg2 from '../../assets/images/banner-img2.png'
import shape7 from '../../assets/images/shape/vector-shape7.png'
import shape8 from '../../assets/images/shape/vector-shape8.png'


import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

const Banner = () => {

    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (

    <React.Fragment>

            {/* If you want to change the video need to update videoID */}

 

                <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
              /*  Colocaras el id de del video */
                videoId='K1s-ApmPml4' 
                onClose={() => setIsOpen(!isOpen)} 
            />
        
        <div className="banner-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-content">
                            <span className="sub-title">DESARROLLO MOVIL</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>¿Quieres diseñar una aplicación para tu empresa? Pruébanos</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Excelentes procesos para excelentes aplicaciones.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">


                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Contáctanos
                                        <span></span>
                                    </Link>

                                    <Link 
                                            to="#"
                                            onClick={e => {e.preventDefault(); openModal()}}
                                            className="video-btn popup-youtube">
                                            <i className="flaticon-google-play"></i> Ver video
                                        </Link>
                        
                                </div>
                         
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={bannerImg2} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>

                    








                </div>
            </div>



    {/*         <div className="vector-shape7">
                <img src={shape7} alt="features" />
            </div>
    
            <div className="vector-shape8">
                <img src={shape8} alt="features" />
            </div> */}



        </div>
        </React.Fragment>
    )
}

export default Banner