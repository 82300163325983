import React from 'react';

import icon1 from '../../assets/images/icons/B_Aplicaciones/1.png'
import icon2 from '../../assets/images/icons/B_Aplicaciones/2.png'
import icon8 from '../../assets/images/icons/B_Aplicaciones/3.png'
import icon9 from '../../assets/images/icons/B_Aplicaciones/4.png'
import icon10 from '../../assets/images/icons/B_Aplicaciones/5.png'
import icon11 from '../../assets/images/icons/B_Aplicaciones/6.png'



const Services = () => {
    return (

        
        <div className="boxes-area pb-70">
            <div className="container">
            <div className="section-title">
                    <h2>Beneficios de las aplicaciones móviles para las empresas</h2>
                </div>
                <div className="row">
            
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon8} alt="banner" />
                            </div>
                            <h3>

                            Obtención de datos
                           
 
                            </h3>
                            <p>Las app son una gran fuente de datos que permiten obtener información de valor sobre los distintos perfiles de usuarios que las utilizan, así como de su comportamiento en el entorno de la app. También son muy utilizadas por las empresas para obtener y procesar datos sobre sus procesos.</p>
                             
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>
                            <h3>

                            Experiencias personalizadas
 
                            </h3>
                            <p>Las app pueden tener un único objetivo o varios, pero mantener una interfaz atractiva y de fácil navegación sigue siendo uno de los elementos clave para mejorar la experiencia de los usuarios, esté diseñada para uso interno de trabajadores o para el gran público.</p>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon11} alt="banner" />
                            </div>
                            <h3>

                            Incremento del engagement

                            </h3>
                            <p>Conocer las necesidades del usuario y el contenido que más le gusta permite incrementar en gran medida el engagement con las marcas. Asimismo, las comunicaciones se realizan de forma directa y en un entorno controlado en el que no serán impactados por la competencia.</p>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                </div>










                <div className="row">
                    

                <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon9} alt="banner" />
                            </div>
                            <h3>
              
                            Notificaciones in-app y push
                    
                            </h3>
                            <p>Las aplicaciones permiten mandar notificaciones a los usuarios dentro y fuera de las app para una para una mayor cercanía y comunican con los usuarios, mejorando la experiencia dentro de la aplicación.</p>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>





                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon10} alt="banner" />
                            </div>
                            <h3>
                     
                            Retargeting de calidad
                 
                            </h3>
                            <p>Permite impactar a los usuarios en otros medios de publicidad online como Google Ads, ampliando el alcance de las campañas en distintos momentos y entornos en relación al contenido que busca.</p>

                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>





                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon1} alt="banner" />
                            </div>
                            <h3>
                            
                            Fidelización de los usuarios
                          
                            </h3>
                            <p>Seguro que ya te imaginas que fidelizar a los usuarios a través de un entorno de interacción propio, adaptado a las necesidades reales de los usuarios, es mucho más fácil que utilizando otro tipo de metodologías.</p>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
export default Services;