import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import caseStudy1 from '../../assets/images/our-mission/our-mission22.png'
import caseStudy12 from '../../assets/images/case-study/case-study12.jpg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const CaseStudy = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])
  

    return (
        <div className="case-study-area bg-fffbf5">
            {display ? <OwlCarousel 
                className="case-study-slides owl-carousel owl-theme"
                {...options}
            > 
                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                 {/*        <img src={starIcon} alt="case-study" />  */}
                                        Aplicativo de recolección de datos 
                                    </span>
                                    <h2>Creador de encuestas sencillo:  pregunta con estilo</h2>
                         
                                <Link to="/seqgps/" className="default-btn">
                                        <i className="flaticon-view"></i>
                                        Saber más 
                                        <span></span>
                                    </Link> 
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to="/seqgps/" className="d-block">
                                        <img src={caseStudy12} alt="case-study" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                       {/*  <img src={starIcon} alt="case-study" /> */} 
                                        Aplicativo de recolección de datos 
                                    </span>
                                    <h2>Obtén respuestas a través de encuestas</h2>
                                 <p>Sé esa persona con excelentes ideas. Las encuestas te dan percepciones prácticas y nuevos puntos de vista.</p>

                        
                                 <Link to="/seqgps/" className="default-btn">
                                        <i className="flaticon-view"></i>
                                        Saber más 
                                        <span></span>
                                    </Link> 
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="case-study-image">
                                    <Link to="/seqgps/" className="d-block">
                                        <img src={caseStudy1} alt="case-study" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </div>
    )
}

export default CaseStudy