import React from 'react'
import {Link} from 'gatsby'
import serviceIcon7 from '../../assets/images/services/S_Movil/android.png'
import serviceIcon8 from '../../assets/images/services/S_Movil/apple.png'
import serviceIcon9 from '../../assets/images/services/S_Movil/mutipla.png'
import serviceIcon10 from '../../assets/images/services/S_Movil/ux.png'
import serviceIcon11 from '../../assets/images/services/S_Movil/bd.png'
import serviceIcon12 from '../../assets/images/services/S_Movil/progre.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'


const OurServices = () => {
    return (
        
        <section className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                      SERVICIOS
                    </span> 
                    <h2>Nuestro servicio en aplicaciones móviles abarca</h2>
      
                </div>

                <div className="row">



                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon7} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Desarrollo de aplicaciones Android
                                </Link>
                            </h3>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                           {/*  <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon8} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Desarrollo de aplicaciones iOS
                                </Link>
                            </h3><br></br>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                       {/*      <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box ">
                            <div className="icon">
                                <img src={serviceIcon9} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Desarrollo de Aplicaciones multiplataforma
                                </Link>
                            </h3>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                       {/*      <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon10} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Diseño de interfaces UI y UX
                                </Link>
                            </h3><br></br>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                           {/*  <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon11} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Base de datos
                                </Link>
                            </h3><br></br><br></br>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                           {/*  <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon12} alt="services" />
                            </div>
                            <h3>
                                <Link to="/contact">
                                Aplicaciones web progresivas
                                </Link>
                            </h3><br></br>
                     {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p> */}
                           {/*  <Link to="/contact" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link> */}
                            <div className="shape">
                                {/* <img src={serviceShape4} alt="services" /> */}
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </section>
    )
}

export default OurServices